<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import TablePage from '@/components/tablePage'
import { shopSaleList } from '@/api/sale/order'
import { updateInstoreStatus } from '@/api/purchase/index'
export default {
  components: { TablePage },
  dicts: ['bill_status'],
  data () {
    return {
      options: {
        mutiSelect: true, // 多选
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        getListApi: shopSaleList,
        body: {},
        listNo: true, // 序号
        title: '门店销售订单',
        check: [], // 选中数据
        rowKey: 'billId',
        exportOption: {
          show: true,
          fastExportUrl: '/api/system/shop/bill/shopSale/listExport',
          exportName: '门店销售订单'
        },
        search: [
          {
            label: '单据日期',
            type: 'datetimerange',
            model: '',
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b })
          },
          {
            keyIndex: 0,
            format: 'datetimerange',
            type: 'timeRange',
            defaultValue: 'month',
            data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
          },
          {
            label: '单据编号',
            seniorSearch: true,
            type: 'input',
            model: '',
            filter: 'billNo'
          },
          {
            label: "到货日期",
            type: "date",
            filter: "arrivalDate",
            model: "",
            seniorSearch: true,
          },
          this.$select({
            key: 'listCustomer',
            option: {
              seniorSearch: true,
              option: { multiple: true }
            }
          }),
          {
            label: '单据状态',
            seniorSearch: true,
            type: 'local',
            model: '',
            filter: 'billStatus',
            option: {
              remote: this.getDicts,
              remoteBody: 'bill_status',
              dataKey: 'data',
              label: 'dictLabel',
              value: 'dictValue',
              filterable: true
            }
          },
          this.$select({
            key: 'listStore',
            option: {
              seniorSearch: true,
              option: { multiple: true }
            }
          }),
          this.$select({
            key: 'listSaleEmployee',
            option: {
              seniorSearch: true,
              option: { multiple: true }
            }
          }),
          this.$select({
            key: 'listPurCreateEmployee',
            option: {
              seniorSearch: true,
              option: { multiple: true }
            }
          }),
          this.$select({
            key: 'listSimpleQuery',
            option: {
              seniorSearch: true,
              option: { multiple: true }
            }
          }),
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        buttons: [
          {
            click: 'add',
            label: '新增',
            icon: 'el-icon-plus',
            type: 'primary',
            disabled: false
          },
          {
            click: "copy",
            label: "复制",
            icon: "el-icon-document-copy",
            type: "success",
            disabled: () => !this.options?.check?.length,
          },
          {
            click: 'audit',
            label: '审核',
            type: 'success',
            btnType: 'dropdown',
            other: [
              {
                click: 'redoAudit',
                label: '反审核'
              }
            ],
            disabled: () => !this.options?.check?.length
          },
          {
            click: 'stop',
            label: '终止',
            icon: 'el-icon-remove-outline',
            type: 'primary',
            disabled: () => !this.options?.check?.length
          },
          {
            label: '打印',
            type: 'primary',
            btnType: 'dropdown',
            print: {}
          },
          {
            click: 'del',
            label: '删除',
            type: 'danger',
            icon: 'el-icon-delete',
            disabled: () => !this.options?.check?.length
          },
          {
            click: 'refresh',
            right: true,
            label: '刷新',
            icon: 'el-icon-refresh',
            type: ''
          }
        ],
        columns: [
          {
            prop: 'billNo',
            label: '单据编号',
            type: 'link',
            click: 'routerLink',
            minWidth: 180
          },
          {
            prop: 'billDate',
            label: '单据日期',
            minWidth: 155
          },
          {
            prop: 'arrivalDate',
            label: '到货日期',
            minWidth: 155
          },
          {
            prop: 'billStatus',
            label: '单据状态',
            type: 'dict',
            minWidth: 120,
            dict: 'bill_status'
          },
          {
            prop: 'billQty',
            label: '单据数量',
            minWidth: 100
          },
          {
            prop: 'billTaxMoney',
            label: '单据金额(元)',
            minWidth: 140
          },
          {
            prop: 'partnerName',
            label: '客户',
            minWidth: 160
          },
          {
            prop: 'storeName',
            label: '仓库',
            minWidth: 160
          },
          {
            prop: 'handBillNo',
            label: '手工单据号',
            minWidth: 120
          },
          {
            prop: 'produceDeptName',
            label: '生产工厂',
            minWidth: 120
          },
          {
            prop: 'deptName',
            label: '部门',
            minWidth: 160
          },
          {
            prop: 'saleEmployeeName',
            label: '业务员',
            minWidth: 160
          },
          {
            prop: 'billRemark',
            label: '备注',
            minWidth: 160
          },
          {
            prop: 'auditBy',
            label: '审核人',
            minWidth: 160
          },
          {
            prop: 'auditTime',
            label: '审核时间',
            minWidth: 160
          },
          {
            prop: 'updateBy',
            label: '修改人',
            minWidth: 120
          },
          {
            prop: 'updateTime',
            label: '修改时间',
            minWidth: 160
          },
          {
            prop: 'createBy',
            label: '创建人',
            minWidth: 120
          },
          {
            prop: 'createTime',
            label: '创建时间',
            minWidth: 160
          }
        ],
        list: []
      }
    }
  },
  methods: {
    async handleEvent (type, row) {
      const selectData = this.options.check
      let url = '/api/system/shop/bill/shopSale/updateBillStatus'
      let billType = '140313'
      switch (type) {
        case 'routerLink':
          {
            const billId = row.billId
            this.$router.push({
              name: 'shopClientSaleOrderDetail',
              query: {
                billId: billId,
                type: 'Update'
              }
            })
          }
          break
        case 'add':
          {
            this.$router.push({
              name: 'shopClientSaleOrderDetail',
              query: {
                type: 'Add'
              }
            })
          }
          break
        case 'audit':
          {
            if (!selectData.length) return
            this.handleBillStatus('审核', selectData, url, billType)
          }
          break
        case 'redoAudit':
          {
            if (!selectData.length) return
            this.handleBillStatus('反审核', selectData, url, billType)
          }
          break
        case 'del':
          {
            if (!selectData.length) return
            this.handleBillStatus('删除', selectData, url, billType)
          }
          break
        case 'copy':
          {
            if (!selectData.length) return
            this.handleBillStatus('复制', selectData, '/api/system/shop/bill/shopSale/copy', billType)
          }
          break
        case 'stop':
          {
            if (!selectData.length) return
            this.handleBillStatus('关闭', selectData, url, billType)
          }
          break
        default:
          break
      }
    },
    handleBillStatus (command, selectData, url, BillType, type) {
      let billData = [] //过滤的数据
      let name = '' //要执行的状态名字
      let tag = '' //提示的状态
      let billStatus = '' //要执行的状态数字
      if (command === '反审核') {
        // billData = selectData.filter(item => (item.billStatus === '2'))
        name = '反审核'
        tag = '已审核'
        billStatus = '0'
      } else {
        // billData = selectData.filter(item => (item.billStatus === '0'))
        if (command === '审核') {
          name = '审核'
          tag = '未审核'
          billStatus = '2'
        } else if (command === '删除') {
          name = '删除'
          tag = '未审核'
          billStatus = '3'
        } else if (command === '关闭') {
          name = '关闭'
          tag = '未审核'
          billStatus = '4'
        }
      }
      const billId = selectData.map(i => i.billId)
      const billNo = selectData.map(i => i.billNo)
      const $this = this
      this.$modal
        .confirm(`是否确认${name}单据编号为"` + billNo + '"的单据项?')
        .then(function () {
          return updateInstoreStatus(
            [...billId],
            billStatus,
            url,
            BillType
          ).then(() => {
            $this.$nextTick(() => {
              $this.options.check = []
            })
            $this.$modal.msgSuccess(`${name}成功`)
            setTimeout(() => {
              $this.$refs.tablePage.getList()
            }, 500)
          })
        })
    }
  }
}
</script>
<style lang="scss" scoped></style>
